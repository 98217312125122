//Breakpoints
$screen-sm-max: 667px;
$screen-md-min: 768px;
$screen-md-max: 1024px;

// Colors
$green: #91D36C;
$blue: #59C3E5;

// Animations
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  50% {
    opacity: 1;
    -webkit-transform: translate3d(0, 15px, 0);
    transform: translate3d(0, 15px, 0);
  }

  75% {
    // -webkit-transform: translate3d(0, -10px, 0);
    // transform: translate3d(0, -10px, 0);
  }

  90% {
    opacity: 1;
    -webkit-transform: translate3d(0, 15px, 0);
    transform: translate3d(0, 15px, 0);
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0);
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes grow {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes peelBack {
  from {
    height: 90px;
    top: 110px;
  }
  to {
    height: 180px;
    top: 30px;

  }
}

@keyframes peelBackCircle {
  from {
    margin-top: -100px;
    box-shadow: -1px 0px 17px 6px rgba(0,0,0,0.75);
  }
  to {
    box-shadow: -1px 0px 17px 6px rgba(0,0,0,0.0);
    margin-top: -130px;
  }
}

@keyframes peelFront {
  from {
    height: 70px;
  }
  to {
  	height: 150px;
  }
}

@keyframes peelFrontCircle {
  from {
    margin-top: -90px;
  }
  to {
    margin-top: -10px;
  }
}

