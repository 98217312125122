@import 'variables';

.stickyHeader {
  position: sticky;
  top: 50px;
  right: 0;
  padding-top: 20px;
  left: 280px;
  padding-bottom: 20px;
  z-index: 5;
  background-color: #f0f0f0;
  h1 {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.course-single {
  padding-left: 220px;
  margin-left: -220px;
  max-width: 1000px;
  padding-bottom: 100px;
  position: relative;
  audio {
    cursor: pointer;
    border-radius: 50px;
    width: 100%;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
  }

  .button.small:focus {
    background-color: #f0f0f0;
  }

  .button.large {
    margin-top: 20px;
  }
  .button.unsave {
    .icon {
      transform: scaleX(-1);
    }
  }
  button.save, .button.unsave {
    position: absolute;
    top: 20px;
    right: 0;
    opacity: 1;
    transition: .3s;
    &.fade {
      opacity: 0;
      pointer-events: none;
    }
    .icon {
      width: 20px;
      height: 20px;
    }
  }

  .assessmentStatus {
    margin-top: 20px;
    background-color: white;
    border-radius: 4px;
    padding: 5px 5px 5px 15px;
    min-height: 0px;
    &.new {
      animation: fadeInUp .4s;
    }
    .flexRow {
      justify-content: space-between;
    }
    .icon {
      width: 20px;
      height: 20px;
      background-size: contain;
      margin-right: 10px;
      padding: 0px;
      background-repeat: no-repeat;
      &.started {
        background-image: url('../img/laptop.svg');
      }
      &.submitted {
        background-image: url('../img/inbox.svg');
      }
      &.completed {
        background-image: url('../img/i-check.svg');
      }
    }
    .status {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 12px;
    }
    p.feedback {
      border-top: 1px solid #f0f0f0;
      margin: 5px -5px -5px -15px;
      font-style: italic;
      padding: 15px;
      font-size: 12px;
      line-height: 18px;
      .icon.feedback {
        display: inline-block;
        width: 20px;
        height: 20px;
        background-image: url('../img/feedback.svg');
        background-size: contain;
        margin-right: 5px;
        position: relative;
        top: 5px;
      }
    }
  }

  h1 {
    font-size: 28px;
    text-align: left;
    font-weight: 600;
    padding-right: 150px;
    @media (max-width: $screen-sm-max) {
      font-size: 20px;
      line-height: 28px;
    }
  }
  p {
    overflow: hidden;
    @media (max-width: $screen-sm-max) {
      max-width: 100%;
    }
  }
}

h3 {
  color: #969696;
  text-transform: uppercase;
  font-size: 18px;
  margin-top: 25px;
  font-weight: 600;
  @media (max-width: $screen-sm-max) {
    font-size: 14px;
    font-weight: 700;
  }
}

.learning-path-single {
  max-width: 920px;
  .actions {
    height: 58px;
    transition: .3s;
    opacity: 1;
    &.fade {
      transform: scale(.5);
      margin: 0;
      height: 0px;
      opacity: 0;
      pointer-events: none;
    }
  }
  h2 {
    transition: .3s;
    font-size: 28px;
    text-align: left;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 22px;
  }
  h3 {
    margin-top: 20px;
  }
  section {
    margin-bottom: 35px;
  }
  .video-content {
    .button {
      margin-right: 10px;
    }
  }
  .course-details {
    margin-right: 150px;
  }
  .pathType {
    background-color: $green;
    position: relative;
    top: -10px;
    padding: 3px 6px;
    display: inline-block;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 4px;
    font-size: 10px;
  }
  .course-list-item {
    @media (max-width: $screen-sm-max) {
      .unsave {
        display: none;
      }
    }
    a {
    @media (max-width: $screen-sm-max) {
      padding-bottom: 25px;
      .timestamp {
        top: auto;
        bottom: 4px;
        left: 10px;
      }
      .course-details {
        margin: 0;
        p {
          line-height: 14px;
        }
      }
    }
    transition: .3s;
    header, p, footer, h2, .button {
      filter: blur(0px);
      pointer-events: all;
    }
    p.feedback {
      padding-right: 75px;
    }
  }
}
  .actions {
    margin: 20px 0;
  }
  .section-nextUp {
    animation: fadeInUp forwards .3s;
  }
  .course-list {
    margin-left: 60px;
    &.disabled {
      pointer-events: none;
      .course-list-item {
        header, p, footer, h2, .button {
          filter: blur(5px);
          pointer-events: none;
        }
      }
    }
    &.nextUp {
      margin-left: 0px;
    }
    .courseWrapper {
      position: relative;
      &.isExcluded {
        border-bottom: 0px;
        .status.icon {
          background-color: #f0f0f0;
          box-shadow: none;
        }
        .courseIndex:before {
          display: none;
        }
      }
      &:hover {
        .courseIndex {
          .icon {
            transform: scale(1.7);
          }
        }
      }
      &:first-child {
        .courseIndex:before {
          height: 50%;
          margin-top: 50%;
          border-radius: 4px 4px 0 0;
        }
      }
      &:last-child {
        .courseIndex:before {
          height: 50%;
          margin-top: -50%;
          border-radius: 0 0 4px 4px;
        }
      }
      &.isCompleted {
        .course-list-item {
          border-left: 0px;
        }
        .courseIndex:before {
          content: '';
          background-color: $green;
        }
      }
    }
    .courseIndex {
      position: absolute;
      left: -85px;
      width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      @media (max-width: $screen-sm-max) {
        transform: translateX(10px);
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 4px;
        background-color: white;
        height: 100%;
      }
      .icon {
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
        transition: .3s;
        height: 20px;
        width: 20px;
        transform: scale(1);
        border-radius: 100%;
        background-color: white;
        position: relative;
        z-index: 1;
        background-repeat: no-repeat;
        background-size: 12px;
        background-position: center center;
        &.in_progress {
          background-image: url('../img/laptop.svg');
        }
        &.submitted, &.grading_in_progress {
          background-position: center top 3px;
          background-image: url('../img/inbox.svg');
        }
        &.hasComments {
          background-image: url('../img/feedback.svg');
        }
        &.saved_for_later {
          background-image: url('../img/pin.svg');
        }
        &.completed {
          background-position: center;
          background-size: cover;
          background-color: $green;
          transform: scale(1.7);
          background-image: url('../img/i-check.svg');
        }
      }
    }
    h2 {
      font-size: 18px;
      margin-top: 10px;
      margin-bottom: -5px;
    }
    p {
      line-height: 18px;
    }
  }

}