@import 'variables';

.collapse {
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  left: 238px;
  z-index: 200;
  border: 0px;
  border-radius: 100%;
  background-color: $blue;
  padding: 0px;
  box-shadow: 1px 2px 4px 0 rgba(0,0,0,0.24);
  transition: .4s;
  top: 73px;
  &[data-show=true], &[data-collapsed=true] {
    opacity: 1;
    pointer-events: all;    
  }
  .icon {
    width: 25px;
    height: 25px;
    display: block;
    background-image: url('../img/caret-white.svg');
    background-size: 10px;
    background-position: center;
    background-repeat: no-repeat;
    transform: rotate(180deg);
    transition: .3s;
  }
  &[data-collapsed=true] {
    left: 48px;
    .icon {
      transform: rotate(0deg);
    }
    &.peek {
      left: 238px;
      opacity: 0;
    }
  }
  @media (max-width: $screen-md-max) {
    opacity: 1;
    pointer-events: all;
    top: 60px;
  }
  @media (max-width: $screen-sm-max) {
    left: 12px !important;
    top: 105px;
    &[data-collapsed=false] {
      left: 210px !important;
    }
  }
}

#sidebar {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 250px;
  padding: 70px 20px 20px;
  z-index: 2;
  transition: .4s;
  overflow-x: hidden;
  margin-right: 120px;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: $screen-sm-max) {
    padding: 145px 20px 20px;
  }
  ul {
    margin: 0;
  }
  .itemLabel {
    position: absolute;
    left: 45px;
    width: 300px;
  }
  &[data-peek=true] {
    .filters {
      left: 0px !important;
      opacity: 1 !important;
    }
  }
  &[data-collapsed=true] {
    width: 60px;
    @media (max-width: $screen-sm-max) {
      width: 50px;
    }
    li button {
      .title {
        opacity: 0;
      }
    }
    h3 {
      height: 0px !important;
      padding: 0px 20px !important;
      color: white !important;
    }
    .entity-meta {
      margin-right: -5px;
      margin-left: -5px;
      .message {
        display: none;
      }
      .pathStatus {
        width: 60px;
        margin-left: -15px;
        margin-top: -40px;
        margin-bottom: 60px;
        text-align: center;
        padding: 0 5px;
        @media (max-width: $screen-sm-max) {
          padding-right: 15px;

        }
      }
      .courseCount {
        display: none;
      }
      .backButton {
        border-bottom: 1px solid $green;
        margin-top: 0px !important;
        @media (max-width: $screen-sm-max) {
          margin-top: -10px;
          padding: 20px 15px;
        }
      }
      header {
        opacity: 0;
      }
      .MuiButton-label {
        margin-left: 5px;
      }
      .course-level {
        justify-content: center;
        width: 30px;
        margin-left: 1px;
        margin-top: -40px;
        &.started {
          margin-top: -70px;
        }
        @media (max-width: $screen-sm-max) {
          margin-left: -3px;
          margin-top: -80px;     
        }
      }
      ul {
        margin-top: -30px;
        &.pathList {
          margin-top: -50px;
        }
        li, a {
          display: none;
          &:first-child {
            display: block;
          }
        }
        .icon {
          width: 25px;
          height: 25px;
          margin: 0;
          margin-left: 2px;
          @media (max-width: $screen-sm-max) {
            margin-left: -2px;
          }
        }
      }
      .buttonText {
        opacity: 0;
      }
      .itemLabel {
        opacity: 0;
        position: absolute;
        left: 45px;
      }
      .category-icon-wrapper {
        position: absolute;
        opacity: 0;
      }
      .category-icon.large {
        width: 25px;
        height: 25px;
        right: 16px;
        top: 130px;
        @media (max-width: $screen-sm-max) {
          opacity: 1;
          top: 205px;
          right: 12px;
        }
      }
      .star {
        width: 10px;
        height: 10px;
        @media (max-width: $screen-sm-max) {
          margin-bottom: -10px;
        }
      }
    }
  }
  &:hover {
    .collapse {
      opacity: 1;
      pointer-events: all;
    }
  }

  li button {
    height: 73px;
    width: 250px;
    justify-content: left;
    margin: 0 -20px;
    border-radius: 0;
    padding: 15px 20px;
    @media (max-width: $screen-sm-max) {
      padding: 5px 15px;
      height: 50px;
    }

    &:focus, &.active {
      background-color: $green;
    }
    .MuiButton-label {
      font-size: 18px;
      text-transform: none;
      font-weight: normal;
    }
    .title {
      opacity: 1;
      transition: .4s;
    }
    .icon {
      width: 20px;
      height: 20px;
      margin-right: 20px;
      background-repeat: no-repeat;
      margin-top: -2px;
      &#dashboard {
        background-image: url('../img/dashboard.svg');
      }
      &#learningPaths, &#learningPathsCompleted {
        background-image: url('../img/learning-path-black.svg');
      }
      &#badges, &#badgesEarned {
        background-image: url('../img/i-badge-black.svg');
      }
      &#explore {
        background-image: url('../img/search.svg');
      }
      &#saved_for_later {
        background-image: url('../img/pin-black.svg');
      }
      &#in_progress {
        background-image: url('../img/laptop-black.svg');
      }
      &#feedback {
        background-image: url('../img/feedback-black.svg');
      }
      &#submitted {
        background-image: url('../img/inbox-black.svg');
      }
      &#completed {
        background-image: url('../img/i-check-black.svg');
      }
    }
  }

  .entity-meta {
    .learningPathCategories {
      display: flex;
      flex-wrap: wrap;
      width: 200px;
      .category-icon-wrapper {
        width: 30px;
        margin: 0;
      }
    }

    h3 {
      margin-bottom: 0px;
      color: black;
      background-color: $green;
      margin: 0px -20px;
      padding: 25px 20px;
      text-transform: none;
      font-weight: 400;
      width: 250px;
      height: 69px;
      transition: .3s;
    }
    .message {
      margin-top: 5px;
    }
    .pathList {
      margin: -5px 0 10px;
    }
    .pathStatus {
      margin-top: 35px;
      width: 100%;
      font-size: 12px;
      animation: fadeInUp forwards .3s;
      transition: .3s;
    }
    .courseCount {
      height: 32px;
      display: flex;
      align-items: center;
      margin-top: 20px;
      width: 180px;
      span {
        font-weight: 700;
        color: $blue;
        font-size: 32px;
        position: relative;
        top: 1px;
        margin-right: 5px;
        transition: .3s;
      }
    }
    .backButton {
      width: 250px;
      padding: 20px;
      margin-left: -20px;
      margin-top: -20px;
      border-radius: 0px;
      transition: .4s;
      @media (max-width: $screen-sm-max) {
        margin-top: -50px;
      }
      &:focus {
        background-color: white;
      }
      .MuiButton-label {
        justify-content: flex-start;
        font-size: 18px;
        color: $blue;
        text-transform: capitalize;
        font-weight: normal;
      }
      .icon {
        margin-right: 10px;
        width: 20px;
        height: 17px;
      }
    }
    header {
      width: 220px;
      color: #999;
      font-size: 14px;
      text-transform: uppercase;
      margin-top: 30px;
      margin-bottom: 10px;
      opacity: 1;
      transition: opacity .4s;
      font-weight: 600;
    }
    .category-icon-wrapper {
      transition: .4s;
      width: 250px;
      display: flex;
      text-transform: capitalize;
      font-size: 12px;
      align-items: center;
      margin-bottom: 10px;
    }
    .category-icon {
      position: relative;
      top: -1px;
      width: 20px;
      display: block;
      height: 20px;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.28);    
      border-radius: 100%;
      margin-right: 5px;
      &.learning-path-icon {
        background-size: cover;
      }
      &.large {
        transition: .4s;
        margin-right: 0px;
        width: 50px;
        height: 50px;
        position: absolute;
        top: 95px;
        right: 10px;
        @media (max-width: $screen-sm-max) {
          top: 141px;
          opacity: 0;
        }
      }
    }
    .course-time {
      color: $blue;
      font-size: 18px;
      font-weight: 700;
      .icon {
        margin-right: 5px;
        position: relative;
        top: -2px;
      }
      .flexRow {
        align-items: center;
      }
    }
    .course-level {
      margin-top: 0;
      transition: .4s;
      display: flex;
    }
    .star {
      transition: .4s;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      margin-right: 3px;
      background-image: url('../img/star.svg');
    }
    .badgeLabel {
      opacity: 1;
      transition: .4s
    }
    ul {
      margin-top: 0;
      transition: margin-top .4s;
      a {
        color: black;
        li {
          &:hover {
            background-color: #f0f0f0;
            .itemLabel {
              margin-left: 5px !important;
            }
          }
        }
      }
      li {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 12px;
        transition: .3s;
        &.learning-path-item {
          line-height: 14px;
          padding: 10px 20px;
          margin: 0 -20px;
          .itemLabel {
            transition: .3s;
            width: 200px;
          }
        }  
      }
    }
    .icon.learningPath {
      background-image: url('../img/learning-path.svg');
      background-size: 90%;
      background-repeat: no-repeat;
    }
    .icon.badge, .icon.learningPath {
      flex-shrink: 0;
      display: block;
      float: none;
      height: 20px;
      width: 20px;
      margin: 0 5px 0 0;
    }
  }

}

.filters {
  width: 220px;
  margin-left: -10px;
  margin-top: 20px;
  position: relative;
  opacity: 1;
  left: 0;
  transition: .6s;
  &.collapsed {
    left: -100px;
    opacity: 0;
  }
  .icon {
    position: absolute;
    width: 12px;
    height: 12px;
    margin-top: 9px;
    &.learningPath {
      background-image: url('../img/learning-path-black.svg');
    }
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 33px;
    strong {
      text-transform: uppercase;
      font-weight: 700;
      flex: 1;
      font-size: 12px;
    }
    .backButton {
      margin-right: -6px;
      color: $blue;
      &:focus {
        background-color: white;
      }
    } 
  }

  input {
    margin: 0;
    padding-left: 20px;
  }
  .category {
    .icon {
      background-image: url('../img/i-categories.svg');
    }
  }
  .learningPath {
    .icon {
      background-image: url('../img/learning-path-black.svg');
    }
  }
  .badgeSelect {
    .icon {
      margin-left: 0px !important;
    }
  }
  .MuiTextField-root .MuiInput-underline:before {
    display: none;
  }
  .inputSelect, .MuiFormControlLabel-root {
    margin-top: 20px;
    .MuiFormLabel-root {
      transform: translate(20px, 22px);
      &.MuiInputLabel-shrink {
        transform: translate(0, 2px) scale(0.75);
      }
    }
    .MuiSelect-root {
      padding-left: 20px;
    }
    .icon {
      margin-top: 23px;
    }
  }
  .inputSearch .MuiFormLabel-root {
    transform: translate(20px, 34px);
    &.MuiInputLabel-shrink {
      transform: translate(0, 12px) scale(0.75);
    }
  
  }
  .MuiFormControl-root {
    width: 100%;
  }

}

.filters.learningPaths {
  .inputSelect.status {
    .icon {
      top: 2px;
      height: 10px;
      width: 10px;
    }
  }
  .MuiSelect-root {
    .icons {
      display: none;
    }
  }
}
[aria-labelledby="label-level-select"] {
  .icons {
    position: absolute;
    right: 10px;
  }
  .icon.star {
    background-image: url('../img/star.svg');
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
    margin-left: 3px;
  }  
}
