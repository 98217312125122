@import 'variables';

.page.profile {
  margin-left: -60px;
  display: flex;
  justify-content: center;

  .loadingWrapper {
    top: 0;
    bottom: 0;
    margin-top: -103px;
    margin-left: -25px;
  }
  .profileContent {
    transition: opacity .4s;
    opacity: 1;
  }
  .profileWrapper {
    padding: 20px;
    transition: .4s;
    position: relative;
    @media (max-width: $screen-sm-max) {
      flex-direction: column;
    }
    &.locked {
      .profilePictureWrapper, .profileContent {
        opacity: .2;
        pointer-events: none;  
      }
    }
    .loader {
      left: 0;
      right: 0;
      position: absolute;
      pointer-events: none;
    }
  }

  .button {
    animation: fadeInUp .4s;
  }

  .saved {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fadeOutDown 1s;
    animation-delay: 1s;
    font-size: 14px;
    font-weight: 700;
    .check {
      display: block;
      background-image: url('../img/i-check.svg');
      margin: 10px;
      width: 50px;
      height: 50px;
      background-size: cover;
      border-radius: 100%;
    }
  }
  h2 {
    margin-bottom: 20px;
    margin-top: 10px;
    @media (max-width: $screen-sm-max) {
      text-align: center;
      margin-top: 40px;
    }
  }
  .MuiInputBase-root {
    margin: 10px 0 40px;
  }
  .MuiInputBase-input[type="text"] {
    border-bottom: 0px;
    margin-bottom: 0px;
    height: 35px;
    width: 250px;
  }
  .flexRow {
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;
    @media (max-width: $screen-sm-max) {
      align-items: center;
    }   
  }

  form {
    @media (max-width: $screen-sm-max) {
      footer {
        display: flex;
        justify-content: center;
      }
    }
  }

  .inputSelect {
    .MuiFormLabel-root {
      transform: translate(20px, 22px);
      &.MuiInputLabel-shrink {
        transform: translate(0, -2px) scale(0.75);
      }
    }
  }
  .profileForm {
    display: flex;
    flex-direction: column;
    footer {
      display: flex;
      justify-content: center;
    }
  }
  .profilePictureWrapper {
    cursor: pointer;
    margin-right: 40px;
    position: relative;
    transition: opacity .4s;
    opacity: 1;
    @media (max-width: $screen-sm-max) {
      margin-right: 0px;
      margin-bottom: 20px;
    }
    img {
      opacity: .2;
    }
    .profilePicture {
      height: 200px;
      width: 200px;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 100%;
      &.userPicture {
        box-shadow: 1px 4px 5px 0 rgba(0,0,0,.4);
      }
    }
    .border {
      width: 206px;
      height: 206px;
      position: absolute;
      left: -3px;
      top: -3px;
      background-image: url('../img/dotted-circle.svg');
      background-size: 100%;
    }
    .tooltip {
      .button {
        margin-top: 0px;
      }
      text-align: center;
      font-weight: 600;
      margin-top: 15px;
      right: 0;
      left: 0;
      position: absolute;
      color: #686868;
      font-size: 12px;
      line-height: 14px;
    }
    .fileUpload {
      width: 200px;
      height: 200px;
      position: relative;
      transform: scale(1);
      transition: .4s;
      &.isDragging {
        transform: scale(1.1);
        .border {
          animation: rotate 22s linear infinite;
        }
      }

      &.isUploading {
        transform: scale(1.1);
        .border {
          animation: rotate 8s linear infinite;
        }
      }

      .tooltip {
        width: 200px;    
        text-align: center;
        font-size: 18px;
        line-height: 20px;  
        position: absolute;
        bottom: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        flex-direction: column;
        span {
          font-weight: 400;
          color: #686868;
          font-size: 12px;
          line-height: 12px;
          display: inline-block;
          margin-top: 10px;
        }

      }
      input {
        cursor: pointer;
        z-index: 5;
        width: 200px;
        height: 200px;
        opacity: 0;
        position: absolute;
      }
    }
  }
}