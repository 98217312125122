@import 'variables';

.page.login {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -50px;
  background-color: $blue;
  text-align: center;
  padding: 20px;
  background-image: url('../img/splash.jpg');
  background-size: cover;
  background-position: right bottom;
  .background {
    background: linear-gradient(120deg, rgba(89,195,229,1) 0%, rgba(89,195,229,1) 35%, rgba(89,195,229,.4) 100%);
    position: fixed;
    top: 0;
    transition: 1s;
    right: 0;
    left: 0;
    bottom: 0;
  }
  img {
    display: block;
    height: 85px;
    width: auto;
    margin: 0 auto;
    @media (max-width: 667px) {
      height: 45px;
    }
  }
  .loginWrapper {
    position: relative;
    z-index: 1;
    animation: fadeInUp 1s;
    margin-top: -50px;
    background-color: white;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  }
  .flexRow {
    justify-content: center;
    padding: 20px;
    &:first-child {
      padding: 40px;
      @media (max-width: 667px) {
        padding: 20px;
      }
    }
  }
  .button.login {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.28);
    padding: 10px;
    border-radius: 4px;
    background-color: white;
  }
  .MuiButton-label {
    text-transform: none;
    font-size: 14px;
    font-weight: normal;
    color: #444 ;
    .icon {
      width: 25px;
      height: 25px;
      background-size: contain;
      background-repeat: no-repeat;
    }

  }
  button {
    text-transform: none;
    font-weight: 300;
    margin: 20px auto;
    display: block;
    background-image: url('../img/i-google.svg');
    background-repeat: no-repeat;
    background-position: left 10px top 45%;
    background-size: 20px;
    padding-left: 40px;
    padding-right: 10px;
  }
}
